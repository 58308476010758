import styled from 'styled-components';
import { BiggerThan } from '../../../enums';

import { TilesStyles, tileBorderColor } from '../../Tile/Tile.styled'

export const WorkSection = styled.section`
`;

export const TileSectionWrapper = styled.div`
    perspective: 600px;
    transform-style: preserve-3d;
    margin-top: 3rem;
`

export const Wrapper = styled.div`
    --tile-border-color: #041012;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 120vh;
    align-items: center;
`

export const Content = styled.div`
    will-change: transform;

    box-sizing: border-box;
    padding: 1.5rem 1.5rem;
    width: 100%;

    @media ${BiggerThan.Small} {
        padding: 1.5rem 3rem;
    }

    @media ${BiggerThan.Medium} {
        padding: 1.5rem 3.5rem;
    }

    @media (min-width: 1140px) {
        max-width: 1000px;
        padding: 0;
    }
`

const tileSpacing = '0rem';

export const Tile = styled.div`
    ${TilesStyles};

    margin: 0 auto ${tileSpacing} auto;
    height: 18vh;
    width: 24rem;
    max-width: 100%;
    cursor: pointer;

    @media ${BiggerThan.Small} {
        height: 30vh;
        /* width: calc(50% - ${tileSpacing}); */
        width: auto;
        &:first-child {
            width: 60%;
        }
        &:nth-child(6n + 2) {
            width: 40%;
        }
        &:nth-child(6n + 3) {
            width: 40%;
        }
        &:nth-child(6n + 4) {
            width: 60%;
        }
    }
    @media ${BiggerThan.Large} {
        height: 30vh;
        width: calc(33.33% - ${tileSpacing});

        /* Experimenting with variable width */
        &:first-child {
            flex-basis: 60%;
        }
        &:nth-child(6n + 2) {
            flex-basis: 40%;
        }
        &:nth-child(6n + 3) {
            flex-basis: 40%;
        }
        &:nth-child(6n + 4) {
            flex-basis: 60%;
        }
        &:nth-child(6n + 5) {
            flex-basis: 35%;
        }
        &:nth-child(6n + 6) {
            flex-basis: 40%;
        }
    }
`;

export const TileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    border: 3px solid ${tileBorderColor};
    margin: -6px;

    transition: all 500ms ease-out;
`;

export const Overlay = styled.div<{
    isVisible: boolean
}>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${tileBorderColor};

    display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
    animation: fadeIn forwards ease-out 500ms;
`


export const TileAnimation = styled.div`
    display: none;
    position: fixed;
    opacity: 1;

    ${TilesStyles};

    > div {
        animation: fadeOut forwards ease-out 100ms;
    }
`;

export const PortalContent = styled.div`
    z-index: 100;
    position: relative;
    color: var(--color-fg-66);
`
