import { IWorkDetail } from "./IWorkDetail";

export const jazz: IWorkDetail = {
    title: 'Jazz',
    role: 'Technical Lead',
    summary: `Jazz is a serverless development platform, that lets developers build and manage APIs and static websites on the click of a button.
    The generated repositories are CI/CD enabled and can deploy to AWS/Azure.`,
    images: {

    },
    employer: {
        name: 'Moonraft Innovation Labs',
        date: '2016 - 2017',
        website: 'https://www.moonraft.com/'
    },
    description: [
        'Jazz allows developers to seamlessly create, deploy, manage, monitor & debug cloud native applications leveraging serverless services. Jazz helps developers focus on code only and solves most of the operational issues around deployment/management.',
        'The platform provides cleaner reusable code templates with all the best practices embedded into them providing the required quick start for developers. With code commits triggering workflows to do rest of the action, a suite of operational tools built into Jazz helps developers securely manage code, configurations and deployments for their cloud native applications.',
        // 'Jazz comes with CI/CD by default so that all the manual, error-prone build and deployment work is completely automated resulting in high quality software with continuous integration, automated tests, code metrics etc. It helps developers focus on continuous code quality & find vulnerabilities in the code before the code goes live! We have started identifying patterns for type of cloud native applications that developers started building and created reusable code templates for these service patterns (APIs, functions, static websites, workflows etc.) so that they can get going in seconds.',
        // 'Pay-per-use makes serverless applications appear insanely cheap. Jazz helps developers to see their spending trend on a per service and environment basis so that they avoid billing mishaps that are not uncommon. This immensely helps developers with not much experience in server management and administration to focus on their code and deliver value to their business.'
    ],
    responsibilities: [
        'Managed India team, and coordinated with TMobile dev team to build UI and microservices',
        'Collaborated with UI/UX designers to create a user-friendly and intuitive interface for the Jazz platform.',
        'Implemented responsive design principles to ensure seamless user experience across different devices.',
        'Contributed to creation of build pipelines for continuous integration and delivery',
        'Followed agile methodology and TDD',
    ],
    technologies: [
        'Angular', 'Jenkins', 'Groovy', 'Python', 'NodeJS', 'AWS', 'Serverless'
    ],
};









