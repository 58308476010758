import React, { useEffect } from 'react';
import { EventName } from '../enums/EventName';
import { logEvent } from '../utils/firebase';
import { useTriggerLazyLoad } from '../utils/useTriggerLazyLoad';

export const useLoadEvent = (eventName: EventName, ref: React.RefObject<HTMLDivElement | null>) => {
    const { isTriggerLazyLoad, registerRef } = useTriggerLazyLoad({});

    useEffect(() => {
        if (isTriggerLazyLoad) {
            logEvent(eventName);
        }
    }, [isTriggerLazyLoad, eventName])

    useEffect(() => {
        if (ref.current && registerRef) {
            registerRef(ref.current);
        }
    }, [ref, registerRef]);
}
