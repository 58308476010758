import styled from 'styled-components';
import { BiggerThan } from '../../enums';

export const AppWrapper = styled.div`
    color: var(--color-fg-66);
    letter-spacing: 0.2px;
`;

export const Main = styled.main`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;

    background-color: var(--color-primary-0, #002C34);
`;


export const Links = styled.div`
    display: none;
    @media ${BiggerThan.Large} {
        display: block;
    }
`
