import styled from 'styled-components';
import { BiggerThan } from '../../../enums';

export const ContactSection = styled.section``;

export const Title = styled.div`
    font-size: 54px;
    font-weight: 700;
    color: var(--color-fg-cc);

    @media ${BiggerThan.Small} {
        font-size: 80px;
    }
`;

export const Description = styled.div`
    font-size: 15px;
    font-weight: 400;

    padding-top: 0.75rem;
    line-height: 1.5;

    @media ${BiggerThan.Small} {
        font-size: 18px;
        padding-top: 2rem;
    }
`;

export const SendMail = styled.a`
    display: inline-block;
    color: var(--color-fg-66);
    background-color: transparent;
    border: 1px solid var(--color-fg-66);
    border-radius: 4px;
    padding: 1.25rem 1.75rem;
    font-size: var(--fz-sm);
    text-decoration: none;
    cursor: pointer;
    margin-right: 1rem;
    @media ${BiggerThan.Small} {
        margin-right: 2rem;
    }
`

export const Instagram = styled(SendMail)`
    color: #833AB4;
    border-color: #833AB4;

    &:link, &:visited {
        color: #833AB4;
    }
`

export const LinkedIn = styled(SendMail)`
    color: #0077b5;
    border-color: #0077b5;

    &:link, &:visited {
        color: #0077b5;
    }
`

export const Links = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media ${BiggerThan.Large} {
        display: none;
    }
`
