import styled from 'styled-components';
import { BiggerThan } from '../../../enums/BiggerThan';
import { Color } from '../../../enums/Color';

export const Section = styled.section<{
    colour: Color,
    zIndex?: number
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    min-height: calc(100vh);
    box-sizing: border-box;
    transition: transform 500ms ease-out;

    background-color: ${({ colour }) => {
        if (colour === Color.Primary3) {
            return `rgba(0,44,52,1)`;
        }
        if (colour === Color.Primary2) {
            return `#023340`;
        }
        if (colour === Color.Transparent) {
            return 'transparent'
        }
        return `var(--color-primary-${colour})`;
    }};
    background: ${({ colour }) => {
        if (colour === Color.Primary3) {
            return `linear-gradient(36deg,#041012 0%,#041012 100%)`;
        }
        if (colour === Color.Primary2) {
            // return `linear-gradient(36deg, #071f23 0%, #071f23 100%)`;
            return `linear-gradient(36deg, #011e25 0%, #011e25 100%)`;
        }
        if (colour === Color.Primary1) {
            return `linear-gradient(36deg,#041012 0%,#041012 100%)`;
            // return `linear-gradient(36deg, #032329 0%, #04313d 100%)`;
        }
        if (colour === Color.Transparent) {
            return ''
        }
        return `var(--color-primary-${colour})`;
    }};
    /* background: ${({ colour }) => {
        if (colour === Color.Primary3) {
            return `linear-gradient(36deg, rgba(0,44,52,1) 0%, rgba(17,96,126,1) 100%)`;
        }
        if (colour === Color.Primary2) {
            return `linear-gradient(36deg, rgba(0,44,52,1) 0%, rgba(17,96,126,1) 100%)`;
        }
        if (colour === Color.Transparent) {
            return ''
        }
        return `var(--color-primary-${colour})`;
    }}; */
    z-index: ${({ zIndex }) => zIndex ?? ''};

    padding: 1.5rem 1.5rem;

    @media ${BiggerThan.Small} {
        padding: 2rem 3rem;
    }

    @media ${BiggerThan.Large} {
        padding: 2rem 4rem;
    }
`;

export const Content = styled.div`
    max-width: 1000px;
    width: 100%;
`;
