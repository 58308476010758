import styled, { css } from 'styled-components';
import { BiggerThan } from '../../enums';

export const tileBorderColor = `#041012`;
export const tileColor = `#011d25`;

export const TilesStyles = css`
    /* background-color: rgba(17, 96, 126, 1); */
    /* background: linear-gradient(36deg, rgba(0,44,52,1) 0%, #023340 100%); */
    background-color: ${tileColor};

    border-radius: 0rem;
    box-sizing: border-box;
`

export const Tile = styled.div`

    padding: 1.25rem 1rem 0.5rem 1rem;
    border: 3px solid ${tileBorderColor};
    box-sizing: border-box;
    height: 100%;

    @media ${BiggerThan.Small} {
        padding: 2rem 1rem 1rem 1rem;
    }
`

export const TileContent = styled.div`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

export const Title = styled.div`
    flex-shrink: 0;
    padding-bottom: 0.5rem;
`

export const Description = styled.div`
    overflow: hidden;
    color: #fff;
`

export const Technologies = styled.div`
    display: flex;
    flex-shrink: 0;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-size: 0.75rem;
    opacity: 0.8;
    color: #fff;
    white-space: nowrap;
    margin-top: auto;

    > div {
        margin-right: 1.5rem;
        flex-shrink: 0;
    }
`
