import { createPortal } from 'react-dom';

interface IPortal {
    children: JSX.Element,
}

const PORTAL_ID = 'portal-root';

export const Portal = ({ children }: IPortal) => {
    // If we are not in the browser, do not set up anything related to modal
    if (typeof document === 'undefined') {
        return null;
    }

    let container = document.getElementById(PORTAL_ID);

    if (!container) {
        container = document.createElement('div');
        container.setAttribute('id', PORTAL_ID);
        document.body.appendChild(container);
    }

    return createPortal(children, container);
}
