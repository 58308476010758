import { useRef } from 'react';
import { Color } from '../../../enums/Color';
import { EventName } from '../../../enums/EventName';
import { useLoadEvent } from '../../../hooks/useLoadEvent';
import { Section } from '../../page/Section';
import * as S from './AboutSection.styled';


export const AboutSection = (): JSX.Element => {
    const ref = useRef<HTMLDivElement | null>(null)

    useLoadEvent(EventName.ViewAbout, ref);

    return (
        <Section colour={Color.Primary2} zIndex={2}>
            <S.AboutSection ref={ref}>
                <S.Title>
                    About
                </S.Title>
                <S.Description>
                    I am an experienced full-stack web developer, with a passion for creating engaging, functional and user-friendly website.
                    I obtained my Bachelor of Engineering from RVCE, Bangalore.

                    <br />
                    <br />

                    In the past I've worked in <a href='https://www.lendingkart.com/' target='__blank'>a FinTech startup
                    </a>, <a href='https://moonraft.com/home' target='__blank'>a design focussed company
                    </a>, <a href='https://www.sixt.com/' target='__blank'>a global corporation</a>. <span></span>

                    I've had opportunity to work in a diverse spectrum of web technologies and collaborate with individuals across the globe.

                    <br />
                    <br />

                    I'm dedicated to building sites that are not only visually stunning, but also intuitive, user-friendly, and
                    accessible to everyone. I'm always eager to learn and stay up-to-date with the latest web development trends,
                    and I strive to integrate the latest best practices into my work.

                    <br />
                    <br />
                    Some of the technologies that I've been working with recently

                    <ul>
                        <li>React</li>
                        <li>Javascript</li>
                        <li>Typescript</li>
                        <li>NodeJS</li>
                        <li>Python</li>
                        <li>AWS</li>
                    </ul>

                </S.Description>
            </S.AboutSection>
        </Section>
    )
};
