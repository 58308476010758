/**
 * @description you need to check if the window is available because of the build on nodejs
 * and the window object is not available!
 * @type {boolean|Window}
 */
export const windowGlobal = typeof window !== 'undefined' && window;

/**
 * Returns whether IntersectionObserver is supported or not.
 * Also returns 'false' for server side rendering.
 */
function isSupported() {
    let result = true;

    if (
        !windowGlobal ||
        !('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype) ||
        !('isIntersecting' in window.IntersectionObserverEntry.prototype)
    ) {
        result = false;
    }

    return result;
}

const isIntersectionObserverSupported = isSupported();

export { isIntersectionObserverSupported };
