import { IWorkDetail } from "./IWorkDetail";

export const catamera: IWorkDetail = {
    title: 'Catamera',
    role: 'Fullstack developer',
    summary: `Catamera, a platform that aims to help banks speed their pace of innovation to keep up with ever-evolving customer expectations.`,
    employer: {
        name: 'Moonraft Innovation Labs',
        date: '2015 - 2016',
        website: 'https://www.moonraft.com/'
    },
    description: [
        'Catamera makes it easy for banks to design and create a user experience with drag-and-drop features. Furthermore, it requires no special IT skills. The widgets library offers pre-built tools, such as PFM and a retirement planner, that connect to the banks’ back end APIs.',
        // 'With its decoupled architecture, it allows seamless integration with diverse data sources and existing technology infrastructures of banks.'
    ],
    responsibilities: [
        'Developed customizable web-components using PolymerJS',
        'Coordinated with product and external client team to understand the requirements, such that the integration is both customizable and scalable',
        `Contributed to the platform's scalability, performance optimization, and responsiveness.`,
        'Developed a user-friendly interface with drag-and-drop functionality for assembling interactive dashboards without technical expertise.',
        'Implemented back-end API endpoints using Node.js to integrate with external data communication and user authentication.'
    ],
    technologies: [
        'PolymerJS', 'NodeJS'
    ],
};
