import * as S from './Icon.styled'

export const CloseIcon = ({ onClick }: { onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }) => {
    return (
        <S.Icon onClick={onClick}>
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M15.924 13.572L2.86.508.406 2.964 13.47 16.027.404 29.09l2.456 2.456 13.064-13.063 13.063 13.063 2.456-2.456L18.38 16.027 31.443 2.964 28.987.508 15.924 13.572z" fill="#fff" fillRule="evenodd" /></svg>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><polygon fill="#191919" fillRule="nonzero" points="84 73.237 81.763 71 68.5 84.263 55.237 71 53 73.237 66.263 86.5 53 99.763 55.237 102 68.5 88.737 81.763 102 84 99.763 70.737 86.5" transform="translate(-53 -71)"></polygon></svg> */}
        </S.Icon>
    )
}
