import { useState, useEffect, useCallback } from 'react';

import { isIntersectionObserverSupported, windowGlobal } from './isIntersectionObserverSupported';

const useTriggerLazyLoad = (options: IntersectionObserverInit) => {
    // Set initial visibility to false
    // For browsers that do not support intersectionObserver, initialVisibility is set to true, essentially creating a fallback behavior
    let initialVisibility = !isIntersectionObserverSupported;

    // Current implementation hides the content for SSR. If your use case requires the opposite,
    // then you may add additional options and handle it accordingly
    if (!windowGlobal) {
        initialVisibility = false;
    }

    const [isTriggerLazyLoad, setIsTriggerLazyLoad] = useState(initialVisibility);
    const [elementNode, setElementNode] = useState<Element | null>(null);

    const callbackFn = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;

        // If element isIntersecting update set isTriggerLazyLoad to true
        // Do not reset isTriggerLazyLoad to false if element goes out of view
        if (entry.isIntersecting) setIsTriggerLazyLoad(true);
    };

    const registerRef = useCallback((node: Element | null) => {
        setElementNode(node);
    }, [setElementNode]);

    useEffect(() => {
        if (isIntersectionObserverSupported !== true) return;

        const observer = new IntersectionObserver(callbackFn, options);
        if (elementNode) observer.observe(elementNode);

        // If element has intersected once clear the observer
        if (elementNode && isTriggerLazyLoad) observer.unobserve(elementNode);

        return () => {
            if (isIntersectionObserverSupported !== true) return;

            if (elementNode) observer.unobserve(elementNode);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementNode]);

    return {isTriggerLazyLoad, registerRef};
};

export { isIntersectionObserverSupported, useTriggerLazyLoad };
