// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLUXUupy9K4kT2vJlS4VLuoN0ej13tnKE",
  authDomain: "personal-portfolio-ver1.firebaseapp.com",
  projectId: "personal-portfolio-ver1",
  storageBucket: "personal-portfolio-ver1.appspot.com",
  messagingSenderId: "284104494345",
  appId: "1:284104494345:web:aa1a2565a2b24c06f517d8",
  measurementId: "G-FVV1XJSZ0P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics }
