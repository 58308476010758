import styled from 'styled-components';
import { TilesStyles } from '../Tile/Tile.styled'
import { BiggerThan } from '../../enums';

export const Wrapper = styled.div`
    ${TilesStyles};
    border-radius: 0;
    position: fixed;
    left: 5vw;
    top: 5vw;
    width: 90vw;
    height: calc(100vh - 10vw);
    opacity: 0;

    color: #e4ffef;
`;

export const TileExpandedScroller = styled.div`
    width: 100%;
    height: calc(100% - 2rem);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding: 2rem 1.5rem 2rem 1.5rem;
    box-sizing: border-box;

    @media ${BiggerThan.Small} {
        padding: 4rem 4rem 1rem 4rem;
    }
`

export const TileExpanded = styled.div`
    margin: 0 auto;
    max-width: 1000px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    width: 1.8rem;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
`

export const Title = styled.div`
    font-size: 40px;
    font-weight: 700;
`;

export const Employer = styled.div`
    font-size: 1rem;
    padding-top: 0.5rem;
    display: flex;
    align-items: baseline;
`

export const EmployerName = styled.div`
    font-size: 1rem;
    opacity: 0.9;
`

export const EmployerDate = styled.div`
    padding-top: 0.4rem;
    padding-left: 0.7rem;
    font-size: 0.7rem;
    opacity: 0.8;
    font-weight: 900;
`

export const Description = styled.div`
    padding-top: 2rem;
    @media ${BiggerThan.Small} {
        padding-top: 0;
    }
`;

export const Image = styled.div`
    padding-top: 2rem;

    img {
        max-width: 100%;
    }
`;

export const Responsibilities = styled.div`
    margin-top: 2rem;
    text-align: left;
`;

export const ResponsibilitiesTitle = styled.div`
    font-size: 1rem;
    font-weight: 900;
`;

export const ResponsibilitiesUl = styled.ul`

    li {
        padding-bottom: 0.5rem;
        line-height: 1.5;
        font-size: 0.8rem;
    }
`;

export const Technologies = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    opacity: 0.75;
    font-size: 0.85rem;

    p {
        margin: 0.5rem 1rem 0.5rem 0;
    }

    @media ${BiggerThan.Small} {
        p {
            margin: 0.5rem 1.8rem 0.5rem 0;
        }
    }
`;
