import { RefObject, useCallback, useEffect, useRef } from 'react';
import { wHeight } from '../utils/parallax';

const getScrollOffset = (refOffset: number) => {
    return window.scrollY + wHeight - refOffset
}

export const getParallaxTranslateY = (refOffset: number, speed: number) => {
    const scrollOffset = getScrollOffset(refOffset);
    const { scrollStop2 } = getScrollStops(refOffset);

    // Note: When the ref is at the center of the viewport, it's net translate is 0,
    // meaning the position with and without parallax effect is same
    return (scrollOffset - scrollStop2) / speed;
}

export const getParallaxOpacity = (refOffset: number) => {
    const scrollOffset = getScrollOffset(refOffset);
    const { scrollStop0, scrollStop1, scrollStop3, scrollStop4 } = getScrollStops(refOffset);

    let opacity = 0;

    if (scrollOffset < scrollStop0) {
        // Before the ref enters the viewport
        opacity = 0;
    } else if (scrollOffset < scrollStop1) {
        // When the ref starts scrolling into viewport
        opacity = Number((scrollOffset / scrollStop1).toFixed(2));
    } else if (scrollOffset < scrollStop3) {
        // Until the ref is completely visible in the viewport
        opacity = 1;
    } else if (scrollOffset < scrollStop4) {
        // When the ref starts scrolling out of viewport
        const scrollDiff = scrollStop4 - scrollStop3;
        opacity = 1 - Number(((scrollOffset - scrollStop3) / scrollDiff).toFixed(2));
    }

    return opacity;
}

export const getScrollStops = (refOffset: number) => {
    const scrollHeight = 2 * wHeight;

    return {
        /** The scroll position at which ref scrolls into the viewport */
        scrollStop0: 0,
        /** The scroll position at which ref should be completely visible */
        scrollStop1: scrollHeight / 4,
        /** The scroll position at which ref is at the center of the viewport */
        scrollStop2: scrollHeight / 2,
        /** The scroll position at which ref should start fading out */
        scrollStop3: scrollHeight * 3 / 4,
        /** The scroll position at which ref scrolls out of the viewport */
        scrollStop4: scrollHeight,
    }
}

/**
 * Basic implementation of Parallax scroll effect
 */
export const useParallax = ({ speed = 3, parentOffset = 0, isDisabled }: { speed: number, parentOffset?: number, isDisabled?: boolean }): { ref: RefObject<HTMLDivElement>, handleScroll: () => void } => {
    const ref = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        const scrollOffset = window.scrollY - parentOffset + window.innerHeight;
        const isHidden = (scrollOffset < 0) || (scrollOffset > (2 * wHeight));

        const opacity = getParallaxOpacity(parentOffset);
        const translateY = getParallaxTranslateY(parentOffset, speed);

        // window.requestAnimationFrame(()=>{
        if (ref.current) {
            ref.current.style.transform = `translateY(${-translateY}px)`
            ref.current.style.opacity = `${opacity}`;
            ref.current.style.visibility = isHidden || opacity === 0 ? 'hidden' : ''
        }
        // })
    }, [parentOffset, speed]);

    useEffect(()=>{
        window?.addEventListener('scroll', handleScroll);

        return ()=>{
            window?.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    return { ref, handleScroll };
}
