import styled, { keyframes } from 'styled-components';

import { BiggerThan } from '../../enums';

export const LinksWrapper = styled.aside`
    position: relative;
    padding-bottom: 1rem;

    @media ${BiggerThan.Large} {
        position: fixed;
        left: 1rem;
        bottom: 1rem;
    }

    @media ${BiggerThan.ExtraLarge} {
        position: fixed;
        left: 2rem;
        bottom: 3rem;
    }
`;

export const Links = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center;

    @media ${BiggerThan.Large} {
        flex-direction: column;
    }
`;

const slideIn = (index: number) => keyframes`
    0% {
        transform: translate(0, ${index * 5}px);
        opacity: 0;
    }
    100% {
        transform: translate(0px);
        opacity: 1;
    }
`

export const Link = styled.li<{
    index: number;
}>`
    opacity: 0;
    animation: ${({ index }) => slideIn(index)} ${({ index }) => `${800 + index * 40}ms`} ${({ index }) => `${index * 10}ms`} ease forwards;
    margin-left: 1rem;

    &:first-child {
        margin-left: 0;
    }

    img, svg {
        width: 1.2rem;
        height: auto;
    }

    @media ${BiggerThan.Large} {
        margin-left: 0;
        margin-top: 2rem;
    }
`

export const A = styled.a`
    text-decoration: none;
`
