import { useRef } from 'react';
import { Color } from '../../../enums/Color';
import { Links } from '../../Links';
import { Section } from '../../page/Section';
import * as S from './ContactSection.styled';
import { EventName } from '../../../enums/EventName';
import { useLoadEvent } from '../../../hooks/useLoadEvent';
import { logEvent } from '../../../utils/firebase';

export const ContactSection = (): JSX.Element => {
    const ref = useRef<HTMLDivElement | null>(null)
    useLoadEvent(EventName.ViewContact, ref);

    const handleClickSendMail = () => {
        logEvent(EventName.ClickButtonSendMail)
    }
    const handleClickInstagram = () => {
        logEvent(EventName.ClickButtonInstagram)
    }
    const handleClickLinkedIn = () => {
        logEvent(EventName.ClickButtonLinkedIn)
    }

    return (
        <div ref={ref} style={{ position: 'relative' }}>
            <Section colour={Color.Primary2} zIndex={1}>
                <S.ContactSection>
                    <S.Title>
                        Get in touch
                    </S.Title>
                    <S.Description>

                        If you want to talk to me about an opportunity or just say hi,
                        <br />
                        <br />
                        <S.SendMail onClick={handleClickSendMail} href="mailto:sunilf.work@gmail.com">Send a mail</S.SendMail>

                        <br />
                        <br />
                        <br />
                        <br />

                        You can also reach out to me on my social profiles, or check them out to know me better
                        <br />
                        <br />
                        <S.Instagram onClick={handleClickInstagram} href="https://www.instagram.com/sunilfernz" target='_blank'>Instagram</S.Instagram>
                        <S.LinkedIn onClick={handleClickLinkedIn} href="https://www.linkedin.com/in/sunil-fernandes-b014a44a/?originalSubdomain=in" target='_blank'>Linkedin</S.LinkedIn>
                    </S.Description>

                </S.ContactSection>
            </Section>

            <S.Links>
                <Links />
            </S.Links>
        </div>
    )
};
