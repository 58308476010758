import { createRef, useEffect, useRef, useState } from 'react';
import { Section } from '../../page/Section';
import { Title } from '../../page/Typography';

import { tiles } from './tiles';

import { Color } from '../../../enums/Color';
import { EventName } from '../../../enums/EventName';
import { useParallax } from '../../../hooks/useParallax';
import { useTileShowcase } from '../../../hooks/useTileShowcase';
import { logEvent } from '../../../utils/firebase';
import { WORK_SECTION_SPEED, wHeightVh } from '../../../utils/parallax';
import { useTriggerLazyLoad } from '../../../utils/useTriggerLazyLoad';
import { Portal } from '../../Portal';
import { Tile } from '../../Tile';
import { TileExpanded } from '../../TileExpanded';
import * as S from './WorkSection.styled';
import { IWorkDetail } from '../../../data/work/IWorkDetail';

export const WorkSection = (): JSX.Element => {
    const [sectionRef, setSectionRef] = useState<HTMLElement | null>(null);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const tileAnimationRef = useRef<HTMLDivElement>(null);
    const tileRefs = tiles.map(() => createRef<HTMLDivElement>());

    const { overlayStyles, tileData, tileStyles, tileExpandedStyles, handleTileClick, handleOutsideClick } = useTileShowcase({
        sectionRef,
        tileRefs,
        wrapperRef,
        tileAnimationRef,
    });

    const { ref, handleScroll } = useParallax({ speed: WORK_SECTION_SPEED, parentOffset: (sectionRef?.offsetTop ?? 0) });

    const handleRefUpdate = (instance: HTMLElement | null) => {
        setSectionRef(instance);
        registerRef(instance)
    }

    const handleTileClickAction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, tile: IWorkDetail) => {
        handleTileClick(event, index);
        logEvent(`${EventName.ClickTile}${tile.title}`);
        event.stopPropagation();
    }

    const handleClick = () => {
        logEvent(EventName.ClickParticleBackground)
    }

    const handleOutsideClickAction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleOutsideClick();
        event.stopPropagation();
    }

    const { isTriggerLazyLoad, registerRef } = useTriggerLazyLoad({});

    useEffect(() => {
        if (isTriggerLazyLoad) {
            logEvent(EventName.ViewWork);
        }
    }, [isTriggerLazyLoad])

    useEffect(() => {
        handleScroll();
    }, [handleScroll]);

    return (
        <Section colour={Color.Primary1} onRefUpdate={handleRefUpdate} styles={{ height: wHeightVh }}>
            <S.Wrapper onClick={handleClick}>
                <S.Content ref={ref}>
                    <Title>
                        Work
                    </Title>
                    <S.TileSectionWrapper>
                        <S.TileContainer ref={wrapperRef}>
                            {
                                tiles.map((tile, index) => (
                                    <S.Tile ref={tileRefs[index]} onClick={event => handleTileClickAction(event, index, tile)} key={tile.title}>
                                        <Tile data={tile} />
                                    </S.Tile>
                                ))
                            }
                        </S.TileContainer>
                    </S.TileSectionWrapper>
                </S.Content>

                <Portal>
                    <S.PortalContent>
                        <S.Overlay isVisible={!!tileData} style={overlayStyles ?? {}} onClick={handleOutsideClickAction} />
                        {tileData && <TileExpanded data={tileData} styles={tileExpandedStyles ?? undefined} onClose={handleOutsideClickAction} />}
                        <S.TileAnimation ref={tileAnimationRef} style={tileStyles ?? {}}>
                            {tileData && <Tile data={tileData} />}
                        </S.TileAnimation>
                    </S.PortalContent>
                </Portal>
            </S.Wrapper>
        </Section>
    )
};
