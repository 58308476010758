import { CardDescription, CardTitle } from '../page/Typography';
import { ITile } from './ITile';
import * as S from './Tile.styled';

export const Tile = ({ data }: ITile): JSX.Element => {
    return (
        <S.Tile>
            <S.TileContent>
                <S.Title>
                    <CardTitle>
                        {data.title}
                    </CardTitle>
                </S.Title>
                <S.Description>
                    <CardDescription>
                        {data.summary}
                    </CardDescription>
                </S.Description>
                <S.Technologies>
                    {data.technologies.map((item) => <div key={item}>{item}</div>)}
                </S.Technologies>
            </S.TileContent>
        </S.Tile>
    )
};
