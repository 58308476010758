import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

export interface ITileShowcase {
    /** Indicates if tile is expanded */
    isTileExpanded: boolean;
}

type ITileShowcaseDispatch = Dispatch<SetStateAction<ITileShowcase>>;

const initialValues = {
    isTileExpanded: false,
};

const TileShowcaseDispatchContext = createContext<ITileShowcaseDispatch>(() => initialValues);
const TileShowcaseStateContext = createContext<ITileShowcase>(initialValues);

const useTileShowcaseDispatch = (): ITileShowcaseDispatch => {
    const context = useContext(TileShowcaseDispatchContext);
    if (context === undefined) {
        throw new Error('useTileShowcaseDispatch must be used within a TileShowcaseProvider');
    }
    return context;
};

const useTileShowcaseState = (): ITileShowcase => {
    const context = useContext(TileShowcaseStateContext);
    if (context === undefined) {
        throw new Error('useTileShowcaseState must be used within a TileShowcaseProvider');
    }
    return context;
};

const useTileShowcaseContext = (): [ITileShowcase, ITileShowcaseDispatch] => [useTileShowcaseState(), useTileShowcaseDispatch()];

const TileShowcaseProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [offersList, setOffersList] = useState<ITileShowcase>(initialValues);

    return (
        <TileShowcaseStateContext.Provider value={offersList}>
            <TileShowcaseDispatchContext.Provider value={setOffersList}>{children}</TileShowcaseDispatchContext.Provider>
        </TileShowcaseStateContext.Provider>
    );
};
export { useTileShowcaseContext, TileShowcaseProvider, useTileShowcaseDispatch, useTileShowcaseState };
