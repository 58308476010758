import React from 'react';
import { CSSProperties } from 'styled-components';
import { Color } from '../../../enums/Color';
import * as S from './Section.styled';

export const Section = (
    {
        colour = Color.Primary1,
        zIndex,
        onRefUpdate,
        children,
        styles,
    }: React.PropsWithChildren & {
        colour?: Color,
        zIndex?: number,
        onRefUpdate?: (ref: HTMLElement | null) => void,
        styles?: CSSProperties
    }
): JSX.Element => {
    return (
        <S.Section ref={onRefUpdate} colour={colour} zIndex={zIndex} style={styles}>
            <S.Content>
                {children}
            </S.Content>
        </S.Section>
    )
};
