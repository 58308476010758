import { Color } from '../../../enums/Color';
import { useParallax } from '../../../hooks/useParallax';
import { Section } from '../../page/Section';
import * as S from './HeroSection.styled';

import { EventName } from '../../../enums/EventName';
import { useLoadEvent } from '../../../hooks/useLoadEvent';
import { HERO_SECTION_SPEED } from '../../../utils/parallax';

export const HeroSection = (): JSX.Element => {
    const { ref } = useParallax({ speed: HERO_SECTION_SPEED, parentOffset: -window.innerHeight / 2 });

    useLoadEvent(EventName.ViewHome, ref)

    return (
        <Section colour={Color.Primary3}>
            <S.Wrapper>
                <S.Content ref={ref}>
                    <S.AnimationRow >
                        <S.Description>
                            <S.Intro>
                                Hey! I'm
                            </S.Intro>
                        </S.Description>
                    </S.AnimationRow>
                    <S.AnimationRow delay={50}>
                        <S.Title>Sunil Fernandes</S.Title>
                    </S.AnimationRow>
                    <S.AnimationRow delay={150}>
                        <S.SubTitle>I am a web developer</S.SubTitle>
                    </S.AnimationRow>
                    <S.AnimationRow delay={300}>
                        <S.Description>
                            I love building elegant, performant and intuitive UX.
                            <br />
                            I'm currently focussed in creating scalable frontend products at SIXT research & development, Bangalore.
                        </S.Description>
                    </S.AnimationRow>
                </S.Content>
            </S.Wrapper>
        </Section>
    )
}
