import React, { useRef, useState } from 'react';


import { CSSProperties } from 'styled-components';
import { tiles } from '../components/sections/WorkSection';
import { useTileShowcaseDispatch } from '../context/useTileShowcaseContext';
import { WORK_SECTION_SPEED, wHeight, workViewPortRatio } from '../utils/parallax';
import { getParallaxTranslateY } from './useParallax';
import { IWorkDetail } from '../data/work/IWorkDetail';

const getInitialStyles = (domRect: DOMRect): CSSProperties => {
    return {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        width: `${domRect.width}px`,
        height: `${domRect.height}px`,
        display: 'block',
        transformOrigin: 'left center',
        transform: `translate(${domRect.x}px, ${domRect.y}px)`
    }
}

const getFinalStyles = (wrapperRect: DOMRect, tileRect: DOMRect): CSSProperties => {
    const expandAsWrapper = `translate(${wrapperRect.x}px, calc(-50% + ${wrapperRect.height / 2}px)) scale(${wrapperRect.width / tileRect.width}, ${wrapperRect.height / tileRect.height})`;

    const marginX = window.innerWidth * 0.05;
    const centerY = window.innerHeight * 0.5;
    const width = window.innerWidth - marginX * 2;
    const height = window.innerHeight - marginX * 2;
    // const marginX = window.innerWidth * 0.05;
    const expandAsViewport = `translate(${marginX}px, calc(-50% + ${centerY}px)) scale(${width / tileRect.width}, ${height / tileRect.height})`
    const isExpandAsWrapper = false;

    return {
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 'auto',
        width: `${tileRect.width}px`,
        height: `${tileRect.height}px`,
        display: 'block',
        transformOrigin: 'left center',
        transform: isExpandAsWrapper ? expandAsWrapper : expandAsViewport,
        transition: 'transform 0.4s ease, opacity 0.2s ease',
    }
}

export const useTileShowcase = (
    {
        sectionRef,
        tileRefs,
        wrapperRef,
        tileAnimationRef,
    }: {
            sectionRef: HTMLElement | null,
            tileRefs: React.RefObject<HTMLDivElement>[],
            wrapperRef: React.RefObject<HTMLDivElement>,
            tileAnimationRef: React.RefObject<HTMLDivElement>,
        }
) => {
    const animationState = useRef<any>(null);

    const [tileData, setTileData] = useState<IWorkDetail | null>(null);
    const [tileStyles, setTileStyles] = useState<CSSProperties | null>(null);
    const [tileExpandedStyles, setTileExpandedStyles] = useState<CSSProperties | null>(null);

    const [overlayStyles, setOverlayStyles] = useState<CSSProperties | null>(null);

    const setTileShowCaseState = useTileShowcaseDispatch();

    const handleOutsideClick = () => {
        if (!animationState.current) {
            return;
        }
        const selectedTile = tileRefs[animationState.current.index].current;

        const body = document.querySelector('body');

        if (tileAnimationRef.current && wrapperRef.current && selectedTile) {
            // Reset the ExpandedTileClone styles to finalStyles
            const tileExpandedStyles = animationState.current.finalStyles;
            tileExpandedStyles.transition = 'all 0s';
            setTileStyles(tileExpandedStyles);

            // Reset the ExpandedTile styles
            setTileExpandedStyles(null);

            // In order to scroll the TileContainer into view, the window is scrolled by scrollOffset,
            // Recalculate the styles based on the scrollOffset
            const tileRect = animationState.current.tileRect;
            tileRect.y = tileRect.y + animationState.current.scrollOffset;
            const tileCollapsedStyles = getInitialStyles(tileRect);
            tileCollapsedStyles.transition = 'transform 0.4s ease, opacity 0.2s ease';
            setTimeout(() => {
                // Using setTimeout so that previous reset styles are applied
                setTileStyles(tileCollapsedStyles);
            }, 0);

            // Reset animationState
            animationState.current = null;

            // Start wrapper transform animation
            wrapperRef.current.style.transform = `none`;

            // Start clone fadeOut
            setTimeout(() => {
                tileAnimationRef.current?.firstElementChild?.setAttribute('style', 'animation-name: fadeIn; opacity: 1;')
            }, 200);

            // Start overlay fadeOut
            setOverlayStyles({
                animationName: 'fadeOut'
            })

            setTimeout(() => {
                // Reset wrapper styles
                wrapperRef.current && (wrapperRef.current.style.pointerEvents = '');

                // FixMe: may not be needed
                setTileShowCaseState({
                    isTileExpanded: false
                })

                // Reset the clicked tile
                selectedTile.style.opacity = '1';

                // Cleanup
                setTimeout(() => {
                    setTileStyles(null)
                    setTileData(null)
                    setOverlayStyles(null)
                    if (body) {
                        body.style.overflow = '';
                    }
                }, 300);
            }, 400);
        }

    }

    const handleTileClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
        const body = document.querySelector('body');

        const scrollY = window.scrollY;
        const offsetTop = (sectionRef?.offsetTop ?? 0) + wHeight * (workViewPortRatio - 1) / WORK_SECTION_SPEED / 2;
        const scrollOffset = scrollY - offsetTop - getParallaxTranslateY(offsetTop, WORK_SECTION_SPEED);

        if (body) {
            body.style.overflow = 'hidden';
        }

        const selectedTile = tileRefs[index].current;


        if (tileAnimationRef.current && wrapperRef.current && selectedTile) {
            const tileData = tiles[index];
            const tileRect = selectedTile.getBoundingClientRect()
            const wrapperRect = wrapperRef.current?.getBoundingClientRect();

            setTileShowCaseState({
                isTileExpanded: true
            })

            const initialStyles = getInitialStyles(tileRect);
            const finalStyles = getFinalStyles(wrapperRect, tileRect);

            animationState.current = {
                initialStyles,
                finalStyles,
                scrollOffset,
                tileRect,
                index,
            };

            selectedTile.style.opacity = '0';
            setTileStyles(initialStyles);
            setTileData(tileData);

            setTimeout(() => {
                setTileStyles(finalStyles);
                setTimeout(() => {
                    setTileStyles({
                        ...finalStyles,
                        opacity: 0,
                    });
                }, 400);

                setTimeout(() => {
                    setTileExpandedStyles({
                        transition: 'opacity 0s ease',
                        opacity: 1,
                    })
                }, 300);

                setTimeout(() => {
                    window.scrollTo({
                        top: offsetTop,
                    });
                    setTileStyles({
                        ...finalStyles,
                        visibility: 'hidden',
                        pointerEvents: 'none'
                    });
                }, 500)
            }, 10)
        }

        if (wrapperRef.current) {
            wrapperRef.current.style.transform = `translate3d(0, 0, -200px)`;
            wrapperRef.current.style.pointerEvents = 'none';
        }
    }

    return {
        tileData,
        tileStyles,
        overlayStyles,
        tileExpandedStyles,
        handleTileClick,
        handleOutsideClick,
    }
}
