import { TileShowcaseProvider } from '../../context/useTileShowcaseContext';
import { App } from './App';

export const AppWithProvider = (): JSX.Element => {
    return (
        <TileShowcaseProvider>
            <App />
        </TileShowcaseProvider>
    )
}
