import styled, { keyframes } from 'styled-components';

import { BiggerThan } from '../../../enums';

const fadeIn = keyframes`
    0% {
        transform: translate(0, 100px);
        opacity: 0;
    }
    100% {
        transform: translate(0px);
        opacity: 1;
    }
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100vh;
    align-items: center;
    /* background: linear-gradient(36deg, rgba(0,44,52,1) 0%, rgba(17,96,126,1) 100%); */
`

export const Content = styled.div`
    /* max-width: 60rem; */
    /* transition: transform 0.3s ease; */
    will-change: transform;

    padding: 1.5rem 1.5rem;

    @media ${BiggerThan.Small} {
        padding: 2rem 3rem;
    }

    @media ${BiggerThan.Large} {
        padding: 2rem 4rem;
    }
`

export const AnimationRow = styled.div<{
    delay?: number;
}>`
    transform: translate(0, 100px);
    opacity: 0;
    animation: ${fadeIn} 800ms ${({ delay }) => `${delay ?? 0}ms`} ease forwards;
`;

export const Intro = styled.div`
    font-size: 12px;

    @media ${BiggerThan.Small} {
        font-size: 14px;
    }
`

export const Title = styled.div`
    font-size: 54px;
    font-weight: 700;
    color: var(--color-fg-cc);

    @media ${BiggerThan.Small} {
        font-size: 80px;
    }
`;

export const SubTitle = styled.div`
    padding-top: 0.75rem;
    font-size: 32px;
    font-weight: 700;

    @media ${BiggerThan.Small} {
        font-size: 48px;
        padding-top: 1rem;
    }
`;

export const Description = styled.div`
    font-size: 15px;
    font-weight: 400;

    padding-top: 0.75rem;
    line-height: 1.5;

    @media ${BiggerThan.Small} {
        font-size: 18px;
        padding-top: 2rem;
    }
`;
