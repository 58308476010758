import styled from 'styled-components';
import { BiggerThan } from '../../../enums';


export const Title = styled.div`
    font-size: 54px;
    font-weight: 700;
    color: var(--color-fg-cc);

    @media ${BiggerThan.Small} { 
        font-size: 80px;
    }
`;

export const CardTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: var(--color-fg-cc);

    @media ${BiggerThan.Small} { 
        font-size: 20px;
    }
`;

export const Description = styled.div`
    font-size: 15px;
    font-weight: 400;

    padding-top: 0.75rem;
    line-height: 1.5;

    @media ${BiggerThan.Small} { 
        font-size: 18px;
        padding-top: 2rem;
    }
`;

export const CardDescription = styled.div`
    font-size: 12px;
    font-weight: 400;

    line-height: 1.5;

    @media ${BiggerThan.Small} { 
        font-size: 14px;
        padding-top: 2rem;
    }
`;