import { IWorkDetail } from "./IWorkDetail";

export const senze: IWorkDetail = {
    title: 'Senze',
    role: 'Product engineer',
    summary: `Senze is a packaged service to build integrated dashboards and reports that deliver powerful data-driven stories on any digital touch-point. It is a tool-agnostic framework that combines design thinking, data, and a library of pre-built visualization components to enhance the decision-making capabilities of executives.`,
    images: {},
    employer: {
        name: 'Piktorlabs',
        date: '2017 - 2019',
        website: 'https://piktorlabs.com/senze'
    },
    description: [
        "Senze has a user-friendly interface that simplifies the process of designing and arranging dashboard components through drag-and-drop functionality. Users can seamlessly integrate data from various sources, including APIs, and spreadsheets, into their dashboards to generate comprehensive insights.",
        // "The tool provides a library of customizable widgets, such as charts, graphs, and tables, enabling users to visually represent data in their preferred format. The dashboards generated are responsive and optimized for various devices, including desktops, tablets, and smartphones. Users can easily share their dashboards with team members and collaborators, fostering efficient communication and decision-making.",
    ],
    responsibilities: [
        'Led a team to build a highly customizable react component library for D3 based charts.',
        'Created lambda services to generate screenshots, pdf and excel reports enabling users to seamlessly extract and share insightful data in various formats.',
        'Built functionality to parse Excel files and transform them into a structured data format, aligning with the requirements of the dashboard.',
        'Made significant contributions to core functionalities, particularly in the areas of authentication and access control.'
    ],
    technologies: [
        'React', 'D3', 'NodeJS', 'MongoDB', 'AWS', 'Firebase'
    ],
};









