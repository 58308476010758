import { IWorkDetail } from "./IWorkDetail";

export const vector: IWorkDetail = {
    title: 'Vector',
    role: 'Fullstack developer',
    summary: `Enterprise web application for accounting, project and user management and user rating`,
    images: {

    },
    employer: {
        name: 'Moonraft Innovation Labs',
        date: '2014 - 2016',
        website: 'https://www.moonraft.com/'
    },
    description: [
        "Vector is an internally developed enterprise web application that serves as a solution for managing accounting, project management, user administration, and user rating processes. My integral involvement in the Vector project encompassed a range of critical responsibilities, significantly contributing to the application's functionalities and overall robustness.",
    ],
    responsibilities: [
        'Implemented REST endpoints using Flask framework for project management, user allocation, and user rating functionalities, contributing to a comprehensive enterprise solution',
        'Developed a responsive and interactive AngularJS web application that prioritized performance and user-friendly navigation.',
        'Managed a team to write unit tests and integration tests, employing testing tools such as Karma, Jasmine, and Protractor.'
    ],
    technologies: [
        'AngularJS', 'Flask', 'Python', 'MySQL', 'Google App Engine', 'Protractor', 'Karma'
    ],
};









