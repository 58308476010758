import { CloseIcon } from '../icons/CloseIcon';
import { CardDescription } from '../page/Typography';
import { ITileExpanded } from './ITileExpanded';
import * as S from './TileExpanded.styled';

export const TileExpanded = ({ data, styles, onClose }: ITileExpanded): JSX.Element => {
    return (
        <S.Wrapper style={styles}>
            <S.TileExpandedScroller>
                <S.TileExpanded>
                    <S.Title>
                        {data.title}
                    </S.Title>
                    <S.Employer>
                        <S.EmployerName>
                            {data.employer.name}
                        </S.EmployerName>
                        {
                            data.employer.date && (
                                <S.EmployerDate>
                                    ({data.employer.date})
                                </S.EmployerDate>
                            )
                        }
                    </S.Employer>
                    <S.Description>
                        <CardDescription>
                            <>
                                {data.summary}
                                {data.description.map((item) => (<p key={item}>{item}</p>))}
                            </>
                        </CardDescription>
                    </S.Description>
                    {/* {
                        'data.images?.content1' && (
                            <S.Image>
                                <img src='./work/jazz2.png' alt='content' />
                            </S.Image>
                        )
                    } */}
                    <S.Responsibilities>
                        <S.ResponsibilitiesTitle>
                            Key Contributions and Responsibilities:
                        </S.ResponsibilitiesTitle>
                        <S.ResponsibilitiesUl>
                            {data.responsibilities.map((item) => (<li key={item}>{item}</li>))}
                        </S.ResponsibilitiesUl>
                    </S.Responsibilities>
                    <S.Technologies>
                        {data.technologies.map((item) => (<p key={item}>{item}</p>))}
                    </S.Technologies>
                </S.TileExpanded>
            </S.TileExpandedScroller>
            <S.CloseIconWrapper >
                <CloseIcon onClick={onClose} />
            </S.CloseIconWrapper>
        </S.Wrapper>
    )
};
