import React from 'react';
import * as S from './Links.styled';

import { Facebook1, Github2, Instagram1, Linkedin3, Soundcloud1 } from '../../assets/logos';
import { logEvent } from '../../utils/firebase';
import { EventName } from '../../enums/EventName';

export const Links = (): JSX.Element => {
    const logos = [{
        label: 'Facebook',
        link: 'https://www.facebook.com/sunilfernz',
        logo: Facebook1
    }, {
        label: 'Github',
        link: 'https://github.com/sunilfernz',
        logo: Github2
    }, {
        label: 'Instagram',
        link: 'https://www.instagram.com/sunilfernz',
        logo: Instagram1
    }, {
        label: 'Linkedin',
        link: 'https://www.linkedin.com/in/sunil-fernandes-b014a44a/?originalSubdomain=in',
        logo: Linkedin3
    }, {
        label: 'Soundcloud',
        link: 'https://soundcloud.com/sunil-fernandez',
        logo: Soundcloud1
    }];

    const handleLinkClick = (label: string) => {
        logEvent(`${EventName.ClickLink}${label}`)
    }

    return (
        <S.LinksWrapper>
            <S.Links>
                {
                    logos.map((logo, index) => (<S.Link key={logo.label} index={logos.length - index}>
                        <S.A href={logo.link} target='_blank' title={logo.label} onClick={() => handleLinkClick(logo.label)}>
                            {/* <img src={logo.logo} alt={logo.label} /> */}
                            <logo.logo />
                        </S.A>
                    </S.Link>))
                }
            </S.Links>
        </S.LinksWrapper>
    )
};
