import { ReactElement } from 'react';
import * as S from './Typography.styled';

interface ITypography {
    children: string | ReactElement;
}

export const Title = ({
    children
}: ITypography): JSX.Element => {
    return <S.Title>{children}</S.Title>
};

export const Description = ({
    children
}: ITypography): JSX.Element => {
    return <S.Description>{children}</S.Description>
};


export const CardTitle = ({
    children
}: ITypography): JSX.Element => {
    return <S.CardTitle>{children}</S.CardTitle>
};

export const CardDescription = ({
    children
}: ITypography): JSX.Element => {
    return <S.CardDescription>{children}</S.CardDescription>
};
