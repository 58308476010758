
export enum EventName {
    // View Event
    ViewHome = 'home_section_viewed',
    ViewAbout = 'about_section_viewed',
    ViewWork = 'work_section_viewed',
    ViewContact = 'contact_section_viewed',

    // Click Event
    ClickTile = 'clicked_tile_',
    ClickLink = 'clicked_link_',
    ClickButtonSendMail = 'clicked_button_sendmail',
    ClickButtonInstagram = 'clicked_button_instagram',
    ClickButtonLinkedIn = 'clicked_button_linkedin',
    ClickParticleBackground = 'clicked_particle_background'
}
