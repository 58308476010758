import styled from 'styled-components';

export const Icon = styled.div`
    width: 100%;
    height: 100%;

    svg {
        width: 100%;
        height: 100%;
    }
`;

