
// import { Header } from '../Header'
import { HeroSection } from '../sections/HeroSection'
import { Links } from '../Links'

import * as S from './App.styled';
import { AboutSection } from '../sections/AboutSection';
import { WorkSection } from '../sections/WorkSection';
import { ContactSection } from '../sections/ContactSection';

import { lazy, Suspense } from 'react';

const Background = lazy(() =>
    import(/* webpackPrefetch: true */ '../page/Background').then(module => ({
        default: module.Background,
    }))
);

function App(): JSX.Element {
    return (
        <S.AppWrapper>
            {/* <Header /> */}
            <S.Main>
                <Suspense fallback={<div />}>
                    <Background />
                </Suspense>
                <HeroSection />
                <AboutSection />
                <WorkSection />
                <ContactSection />
            </S.Main>
            <S.Links>
                <Links />
            </S.Links>
            <div style={{ zIndex: 1 }} id='portal-root' />
        </S.AppWrapper>
    );
}

export { App };
